import React from "react";

import type { NextPage, GetStaticProps, GetStaticPaths } from "next";
import type { SSRConfig } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { FORBIDDEN_LOCALES, REVALIDATE } from "@/config";
import { Dirs } from "@/contentful/content-model/types";
import { layoutGateway } from "@/queries/gateways/layout";
import { pageGateway } from "@/queries/gateways/page";
import SubPageQuery from "@/queries/sub-page.graphql";
import { Template } from "@/templates/sub-page";
import type {
	BlogPost,
	BusinessInfoCollection,
	NavigationCollection,
	Person,
	SubPage,
	SubPageCollection,
	TextCollection,
} from "@/types/contentful-api";
import { buildMetaTags, getSEOAsset, localizeCanonical } from "@/utils/seo";

type EntryListCollection = Record<string, SubPage[] | BlogPost[] | Person[]>;

type PagesSubpageProps = SSRConfig & {
	url: string;
	data: {
		page: SubPage;
		entryListCollection: EntryListCollection;
		textCollection: TextCollection;
		headerNavigation: NavigationCollection;
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	};
};

// TODO: investigate amount of re-renders on page level
const PagesSubpage: NextPage<PagesSubpageProps> = ({ data, url }) => (
	<Template data={data} url={url} />
);

export const getStaticProps: GetStaticProps<PagesSubpageProps> = async ({ params, locale }) => {
	if (FORBIDDEN_LOCALES.includes(locale)) {
		return {
			notFound: true,
			revalidate: REVALIDATE,
		};
	}

	try {
		const { slug } = params as Record<string, string>;

		const translations = await serverSideTranslations(locale);

		const pageData = await pageGateway<SubPage, { pageCollection: SubPageCollection }>(
			SubPageQuery,
			{ dir: Dirs.pages, slug: slug, locale }
		);
		const layoutData = await layoutGateway({ locale });

		// MetaTags mapping
		pageData.page.meta = buildMetaTags({
			defaultMeta: layoutData.metaCollection.items[0],
			pageMeta: pageData.page.meta,
			titleOverride: pageData.page.headline,
			imageOverride: getSEOAsset(pageData.page.keyVisual),
		});

		const url = localizeCanonical(pageData.page.meta.robots, Dirs.pages, locale, [slug]);

		return {
			props: {
				...translations,
				url,
				data: {
					...pageData,
					...layoutData,
				},
			},
			revalidate: REVALIDATE,
		};
	} catch (error: unknown) {
		console.error(error);
		return {
			notFound: true,
			revalidate: REVALIDATE,
		};
	}
};

export const getStaticPaths: GetStaticPaths = async () => {
	return { paths: [], fallback: "blocking" };
};

export default PagesSubpage;
